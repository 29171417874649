<template>
  <div class="vx-row no-gutter items-center justify-center">
    <div class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 m-0">
      <vx-card class="login-card py-3 px-3">
        <div slot="no-body" class="">
          <div class="vx-col sm:w-full">
            <div class="px-6 pt-8 login-tabs-container">

              <div class="vx-card__title mb-4">
                <h4 class="mb-4">Confirmación de cambio de correo.</h4>
              </div>

              <template v-if="isMounted && completed">
                <vs-alert class="h-full mb-5" color="success">
                  <span class="bold">¡Tu dirección de correo ha sido actualizada exitosamente!</span>
                </vs-alert>
                <p>{{successMssg}}</p>
              </template>

              <template v-if="isMounted && !completed">
                <vs-alert v-if="errorMssg" class="h-full mb-5" color="danger">
                  <span>{{ errorMssg }}</span>
                </vs-alert>
                <p>Si tienes dudas por favor contáctanos a través del chat de la plataforma.</p>
              </template>

              <div class="mt-base mb-6">
                <div class="text-dark">
                  Ir a la <span><router-link class="bold ml-1 inline-link-primary text-dark" to="" @click.native="backToLogin">página de inicio.</router-link></span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
const errorMssgDef = "Ha ocurrido un error al agregar al representante, intente más tarde."
export default {
  name: "EmailChangeToken",
  props: ['token'],
  data(){
    return {
      completed: false,
      errorMssg: null,
      isMounted: false,
      successMssg: null,
    }
  },
  async beforeMount(){
    this.isMounted = false
    this.showLoading(true)
    await this.checkForToken();
    this.isMounted = true
    this.showLoading(false)
  },
  methods: {
    ...mapMutations('auth', ['setNotLogged', 'cleanSession']),
    async checkForToken(){
      try {
        const payload = {token: this.$props.token};
        const res = await axios.put('/api/auth/user/email/confirm', payload);
        this.completed = true;
        this.successMssg = res.data.message;
        this.cleanSession();
      }
      catch(error) {
        this.completed = false;
        if(error.response && error.response.data && error.response.data.message){
          this.errorMssg = error.response.data.message;
        }
        else {
          this.errorMssg = errorMssgDef;
        }
      }
    },
  }
}
</script>

<style scoped>

</style>